<template>
  <vue-date-picker
    eagle-datetime-picker="root"
    :valueType="valueType"
    :type="selectType"
    :lang="lang"
    :range="range"
    :disabled="disabledStatus"
    :clearable="clearable"
    v-model="timeValue"
  >

    <template v-slot:input="$attrs">
      <v-text-field
        dense
        outlined
        hide-details
        :label="label"
        autocomplete="off"
        v-on="$attrs.events"
        :readonly="readonly"
        :disabled="disabled"
        :placeholder="$t(placeholder)"
        v-model="$attrs.props.value"
      ></v-text-field>
    </template>
  </vue-date-picker>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/zh-tw'

/**
 * 參考: https://github.com/mengxiong10/vue2-datepicker
 */
export default {
  props: {
    value: {
      type: [String, Date, Array],
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    format: {
      type: String,
      default: 'date',
    },
    type: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    timeValue: null,
  }),
  mounted() {
  },
  methods: {
    setValue() {
      this.timeValue = this.value
    },
  },
  computed: {
    disabledStatus() {
      if(this.readonly === true) return true
      if(this.disabled === true) return true
      return false
    },
    lang() {
      return {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      }
    },
    selectType() {
      return this.type || this.format
    },
    valueType() {
      if(this.format === 'year') {
        return 'YYYY'
      }

      if(this.format === 'date') {
        return 'YYYY-MM-DD'
      }

      if(this.format === 'datetime') {
        return 'YYYY-MM-DD HH:mm:ss'
      }

      if(this.format === 'time') {
        return 'HH:mm:ss'
      }

      return this.format
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.setValue()
      },
    },
    timeValue: {
      handler() {
        if(this.timeValue == this.value) return
        this.$emit('input', this.timeValue)
      },
    },
  },
  components: {
    vueDatePicker: DatePicker,
  },
}
</script>

<style lang="sass" type="text/sass">
*[eagle-datetime-picker="root"].mx-datepicker
  width: 100%
  .mx-icon-calendar
    opacity: 0
    pointer-events: none
    width: 0
    height: 0
  //.mx-input
  //  height: 40px
  //  font-size: 16px
</style>
